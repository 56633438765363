// @flow

import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { addLocale, useLocale } from "ttag";

import { PageContent, BlogOverview } from "@containers";
import { BlogOverviewBreadcrumb } from "@components";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { EVENT_MAP, type PageImpressionParams } from "../models/events";
import { TrackPageImpression } from "../components/Tracker/Tracker";

const BlogOverviewTemplate = ({
    data: {
        contentfulNavigation: { navigationItems },
        c21Translation: { translations },
        footerItems,
        allContentfulWebLandingPage: { nodes },
    },
    pageContext: {
        locale,
        translationSlugs,
        pageCount,
        currentPage,
        tag,
        country,
    },
}: *) => {
    addLocale(locale, translations);
    useLocale(locale);
    const gtmDispatcher = useGTMDispatch();
    useEffect(() => {
        const params: PageImpressionParams = {
            agency: null,
            agencypage: "false",
            language: locale,
            pagename: "Blog overview",
            typetemplate: "blog-overview",
        };
        TrackPageImpression(gtmDispatcher, EVENT_MAP.PageImpression, params);
    }, []);

    return (
        <PageContent
            locale={locale}
            navigationItems={navigationItems}
            footerItems={footerItems}
            translationSlugs={translationSlugs}
            Breadcrumb={<BlogOverviewBreadcrumb locale={locale} />}
            country={country}
        >
            <BlogOverview
                blogs={nodes}
                locale={locale}
                pagination={{ pageCount, currentPage }}
                tag={tag}
            />
        </PageContent>
    );
};

export default BlogOverviewTemplate;

export const query = graphql`
    query ($locale: String!, $blogPagesIds: [String!], $country: String!) {
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "common.text.language"
                    "landing.blog.article"
                    "slug.blog-overview"
                    "breadcrumbs.blog*"
                    "blog.category.*"
                    "blog.filter.title"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                ]
            )
        }
        allContentfulWebLandingPage(
            filter: { id: { in: $blogPagesIds }, node_locale: { eq: $locale } }
        ) {
            nodes {
                slug
                pageContent {
                    ... on ContentfulBlog {
                        title
                        heroImage {
                            fluid(maxWidth: 568, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                    }
                }
            }
        }
    }
`;
